.portfolio__container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:2.5rem;
}
.portfolio__item{
    background:var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: 0.3s;
}
.portfolio__item:hover{
    border-color:var(--color-primary-variant);
    background: transparent;
}
.portfolio__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}
.portfolio__item-image >img{
    aspect-ratio: 8 / 5;
}
.portfolio__item h3{
    margin: 1.2rem 0 2rem;
}
.portfolio__item-cta1 {
    display: flex;
    justify-content: space-between;
}
.portfolio__item-cta {
    display:flex;
    gap: 1rem;
    margin-bottom: 1rem;
}
.more__projects{
    /* display:flex; */
    gap: 1rem;
    margin-bottom: 1rem;
    margin: 0 auto;
    text-align: center;
}
.portfolio__item-linkedin-post{

    padding-top: 10px;
}
/* ====== Medium devices ====== */
@media screen and (max-width:1200px) {
    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;;
    }
}
/* ====== Small devices ====== */
@media screen and (max-width:800px) {
    .portfolio__container{
        display: block; 
        grid-template-columns: 1fr;
        gap: 1rem;;
    }
    .portfolio__item{
        margin-bottom: 1rem;
    }
}

/* Popup style */
.popupcv-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
}

.popupcv-box .box {
    position: relative;
    width: 80vh;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: var(--color-bg-variant);
    border-radius: 1rem;
    padding: 20px;
    /* border: 1px solid #999; */
    
    overflow: auto;
}

.popupcv-box  .close-icon2 {
    content: 'X';
    color: white; /**/
    cursor: pointer;
    /* position: relative; */
    margin-left: 98%;
    display: inline-block;
    /* right: calc(100% - 30px); */
    /* top: calc(100vh - 85vh - 33px); */
    /*background: #ededed;*/
    *background: red;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 2px solid #999;
    font-size: 20px;
}

.popupcv__container{
    display: grid;
    grid-template-columns:1fr;
    /* gap:10%; */
    gap:20px
}
.popupcv__img{
    border-radius: 2rem;
}
.popupcv__img img{
    border-radius: 2rem;
}
/* .popup__content{
    margin: auto auto;
} */
.popupcv__content p{
    padding-top: 1rem;
    
    color: var(--color-white);
}
.popupcv-box .box h2{
    /* margin-bottom:2rem; */
    margin-bottom:0rem;
    text-align: center;
    color:var(--color-light)
}
.popupcv__content a{
    margin :1rem auto ;
}

/* ====== Medium devices ====== */
@media screen and (max-width:1224px) {
    .popupcv__container{
        grid-template-columns: 1fr;
        gap: 1.2rem;
    }
    .popupcv-box .box {
        width: 70%;
    }
    
}
/* ====== Small devices ====== */

@media screen and (max-width:600px) {
    .popupcv__container{
        grid-template-columns: 1fr ;   
    }
    .popupcv-box .box {
        width: 90%;
    }
    /* .close-icon{
        display: none;
    } */
}

section h4{
   text-align: center;
   color:var(--color-light) 
}
section h2{
    margin-top: 1rem;
    margin-bottom: 1.5rem;
 }
.about__container{
    display: grid;
    grid-template-columns:35% 50%;
    gap:15%;
}
.about__me{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg , transparent, var(--color-primary),transparent);
    display:grid;
    place-items: center;
}
.about__me-image{
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(15deg);
    transition: 0.3s;
}
.about__me-image >img{
    aspect-ratio: 1 / 1;
    filter: brightness(80%);
}
.about__me-image:hover{
    transform: rotate(0deg);
}
.about__cards{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:1.5rem;
}
.about__card{
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: 0.3s;
}
.about__card:hover{
    background: transparent;
    border-color:var(--color-bg-variant) ;
    cursor: default;
}
.about__icon{
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}
.about__card h5{
    font-size: 0.95rem;
}
.about__card small{
    font-size: 0.8rem;
    color: var(--color-light);
}
.about__content p{
    color:var(--color-light);
    margin: 2rem 0 2.5rem;
}
/* ====== Medium devices ====== */
@media screen and (max-width:1024px) {
    .about{
        grid-template-columns: 1fr;
        gap:0;
    }
    .about__me{
        width:80%;
        margin : 2rem auto 4rem;
    }
    .about__content p{
        margin: 1rem 0 1.5rem;
    }
}
/* ====== Small devices ====== */
@media screen and (max-width:600px) {
    .about__container{
        display: block;
    }
    .about__me{
        width:65%;
        margin : 0 auto 3rem;
    }
    .about__cards{
        grid-template-columns:1fr 1fr;
        gap:1rem;
    }
    .about__content{
        text-align: center;
    }
    .about__content p {
        margin: 1.5rem 0;
    }
}

/* Popup style */
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: var(--color-bg-variant);
    border-radius: 1rem;
    padding: 20px;
    /* border: 1px solid #999; */
    
    overflow: auto;
}

.close-icon {
    content: 'X';
    color: white; /**/
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    /*background: #ededed;*/
    /* background: red; */
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 2px solid #999;
    font-size: 20px;
}

.popup__container{
    display: grid;
    grid-template-columns:35% 55%;
    gap:10%;
}
.popup__img{
    border-radius: 2rem;
}
.popup__img img{
    border-radius: 2rem;
}
/* .popup__content{
    margin: auto auto;
} */
.popup__content p{
    padding-top: 1rem;
    
    color: var(--color-white);
}
.popup__content a:nth-child(2){
    margin-right: 20px;
}
.box h2{
    margin-bottom:2rem;
    text-align: center;
    color:var(--color-light)
}
.popup__content a{
    margin :1rem auto ;
}

/* ====== Medium devices ====== */
@media screen and (max-width:1024px) {
    .popup__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
    .box{
        width: 80%;
    }
    
}
/* ====== Small devices ====== */

@media screen and (max-width:600px) {
    .popup__container{
        grid-template-columns: 1fr ;   
    }
    .box{
        width: 90%;
    }
    /* .close-icon{
        display: none;
    } */
}
